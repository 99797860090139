import { NMAAHCPropTypes, Theme } from "assets";
import { ActionButton, FormattedText, Metadata, VideoPlayer } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./video-promo.module.scss";

const VideoPromo = ({
  backgroundCoverToggle,
  backgroundImageUrl,
  buttonImage,
  caption,
  coverImage,
  fontColor,
  headline,
  isConstellation,
  loop,
  showMetadata,
  screenReaderText,
  src,
  theme,
  type,
  withComponentSpacing,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const captionTheme = Theme.addClass(Theme.Subtle, false);
  const captionClassName = classNames(
    styles.caption,
    type === "standard" ? captionTheme : undefined
  );

  const headlineClassName = classNames(styles.headline, {
    [styles.leftAlign]: type === "standard",
  });

  const summaryClassName = classNames({
    [styles.featuredSummary]: type === "featured",
    [styles.standardSummary]: type === "standard",
    hidden: type === "featured" && showVideo,
  });

  const containerClassName = classNames(
    styles.videoPromo,
    theme === Theme.Dark && Theme.addClass(Theme.Dark, true),
    {
      "container-fluid": withComponentSpacing,
      [styles.componentMargin]: withComponentSpacing,
      [styles.lightFont]: fontColor == "light",
      [styles.darkFont]: fontColor == "dark",
      [styles.withBackgroundImage]: backgroundImageUrl || theme === Theme.Dark,
      [styles.backgroundCover]: backgroundCoverToggle,
    }
  );

  const rowClassName = classNames("center-xs", {
    row: withComponentSpacing,
  });

  const columnClassName = classNames({
    "col-sm-10 col-md-9 col-lg-7": withComponentSpacing && type === "standard",
    "col-xs-12": withComponentSpacing,
  });

  const images = [];

  if (type === "featured") {
    images.push({
      classes: [styles.featured, "hidden-mobile"],
      image: coverImage?.landscape,
    });
    images.push({
      classes: [styles.featured, "hidden-desktop", "hidden-tablet"],
      image: coverImage?.portrait,
    });
  } else {
    images.push({ classes: [], image: coverImage?.landscape });
  }

  const renderActionButton = () => (
    <ActionButton
      icon="play"
      image={buttonImage}
      onClick={() => setShowVideo(true)}
      screenReaderText={screenReaderText || headline}
      text={"Watch Video"}
    />
  );

  return (
    <div
      className={containerClassName}
      data-testid="video-promo"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className={rowClassName}>
        <div className={columnClassName}>
          {!showVideo &&
            // generate separate blocks for desktop and mobile
            images.map((data, i) => (
              <>
                {type === "standard" && headline && (
                  <FormattedText
                    className={headlineClassName}
                    outerElement={<h2 />}
                    text={headline}
                    deepLink
                  />
                )}
                <div
                  className={classNames(styles.coverImage, ...data.classes)}
                  key={i}
                >
                  <GatsbyImage
                    alt={coverImage?.altText}
                    image={getImage(data.image)}
                  />
                  <div className={summaryClassName}>
                    {type === "standard" && renderActionButton()}
                    {type === "featured" && (
                      <FormattedText
                        className={headlineClassName}
                        outerElement={<h2 />}
                        text={headline}
                        deepLink
                      />
                    )}
                    <FormattedText
                      className={captionClassName}
                      text={caption}
                    />
                    {type === "standard" && showMetadata && (
                      <Metadata
                        {...coverImage}
                        noLine={isConstellation || !caption}
                        type="component"
                      />
                    )}
                    {type === "featured" && renderActionButton()}
                  </div>
                </div>
              </>
            ))}
          {showVideo && (
            <>
              {type === "standard" && headline && (
                <FormattedText
                  className={headlineClassName}
                  outerElement={<h2 />}
                  text={headline}
                  deepLink
                />
              )}
              <div>
                {/* TODO: youtube patch */}
                <VideoPlayer loop={loop} muted={false} src={src} autoplay ui />
              </div>
              <div className={summaryClassName}>
                <FormattedText className={captionClassName} text={caption} />
                {type === "standard" && showMetadata && (
                  <Metadata
                    {...coverImage}
                    noLine={isConstellation}
                    type="component"
                  />
                )}
              </div>
            </>
          )}
          {type === "featured" && showMetadata && (
            <>
              <br />
              <Metadata {...coverImage} type="component" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

VideoPromo.propTypes = {
  backgroundCoverToggle: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  buttonImage: NMAAHCPropTypes.Image,
  caption: PropTypes.string,
  coverImage: NMAAHCPropTypes.Image,
  fontColor: PropTypes.string,
  headline: PropTypes.string,
  isConstellation: PropTypes.bool,
  loop: PropTypes.bool,
  screenReaderText: PropTypes.string,
  showMetadata: PropTypes.bool,
  src: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(["default", "dark"]),
  type: PropTypes.oneOf(["standard", "featured"]),
  withComponentSpacing: PropTypes.bool,
};

VideoPromo.defaultProps = {
  isConstellation: false,
  showMetadata: true,
  type: "standard",
  withComponentSpacing: true,
};

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const VideoPromoFragment = graphql`
  fragment VideoPromoFragment on CraftAPI_componentList_videoPromo_BlockType {
    __typename
    id
    coverImage {
      ... on CraftAPI_image_Asset {
        ...ImageMetadataFragment
        id
        title
        url
        landscape: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, aspectRatio: 2)
          }
        }
        portrait: imageFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        altText
      }
    }
    caption
    theme
    fontColor
    headline
    promoType
    src
    loop
    backgroundCoverToggle
    backgroundImage {
      ... on CraftAPI_image_Asset {
        url
      }
    }
    buttonImage {
      ... on CraftAPI_image_Asset {
        id
        url
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    videoSrc {
      embeddedAsset {
        url
      }
    }
  }
`;

const convert = (videoPromo) => {
  return (
    <VideoPromo
      backgroundCoverToggle={videoPromo.backgroundCoverToggle}
      backgroundImageUrl={videoPromo.backgroundImage?.[0]?.url}
      buttonImage={videoPromo.buttonImage[0]}
      caption={videoPromo.caption}
      coverImage={videoPromo?.coverImage[0]}
      fontColor={videoPromo?.fontColor}
      headline={videoPromo.headline}
      key={videoPromo.id}
      loop={videoPromo.loop}
      src={videoPromo.videoSrc[0]?.embeddedAsset?.url || videoPromo.src}
      theme={videoPromo.theme}
      type={videoPromo.promoType}
    />
  );
};

export { convert, VideoPromo as default, VideoPromoFragment };
